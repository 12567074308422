import axios from './index'

export default {
  getCustomerFormTemplateList() {
    return axios.get('/customerFormTemplate/data')
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getCustomerFormTemplate(customerFormTemplateId) {
    return axios.post('/customerFormTemplate/DataCustomerFormTemplate', { id: customerFormTemplateId })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  submitNewCustomerFormTemplate(model) {
    return axios.post('/customerFormTemplate/upsert', model)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
}
