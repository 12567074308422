<template>
  <b-container fluid>
    <ValidationObserver ref="customerFormTemplatebserver" v-slot="{ invalid }">
      <form>
        <b-row>
          <b-form-group class="col-md-6" :label="$t('Name')" label-for="formName">
            <ValidationProvider :name="$t('Name')" rules="required" v-slot="{ errors }">
              <b-form-input :class="(errors.length > 0 ? ' is-invalid' : '')" v-model="model.formName" type="text"></b-form-input>
              <b-form-invalid-feedback>{{ $t('ValidationMessage.CannotBeEmpty') }}</b-form-invalid-feedback>
            </ValidationProvider>
          </b-form-group>
          <b-form-group class="col-md-6" :label="$t('IsSurvey')" label-for="isSurvey">
            <v-select v-model="model.isSurvey"
                      :reduce="s => s.id"
                      label="name"
                      :clearable="false"
                      :options="yesNoList">
            <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
          </b-form-group>
        </b-row>
        <b-row style="justify-content:right;margin-bottom:10px;">
          <b-button variant="light" @click="addNewQuestion()"><font-awesome-icon icon="fa fa-plus" />&nbsp;{{$t('AddQuestion')}}</b-button>
        </b-row>
        <div v-if="model.customer_FormTemplateQuestion && model.customer_FormTemplateQuestion.length > 0">
          <b-row v-for="(question,index) in model.customer_FormTemplateQuestion" :key="index">
            <h4><span class="badge badge-pill badge-primary">{{$t('Question')}} {{index+1}}</span></h4>
            <customer-form-template-question :question="question"
                                             :questionTypes="questionTypes"
                                             :canMoveUp="CanMoveUpChildQuestion(question)"
                                             :canMoveDown="CanMoveDownChildQuestion(question)"
                                             @MoveUp="reorderQuestions"
                                             @MoveDown="reorderQuestions"
                                             @CopyQuestion="CopyChildQuestion"
                                             @DeleteQuestion="DeleteChildQuestion"
                                             :key="'q_'+index" />
          </b-row>
        </div>

        <modal-footer-button @submit="submitNewCustomerFormTemplate"></modal-footer-button>

      </form>
    </ValidationObserver>
  </b-container>
</template>

<script>
  import customerFormTemplate from '../../../services/customerFormTemplate'
  import commonServices from '../../../services/common'

  import { refreshSmartDuoData } from '../../../helpers/dataRefresher'
  import ModalFooterButton from '../../../components/shared/ModalFooterButton'

  import CustomerFormTemplateQuestion from '../../../components/definitions/CustomerFormTemplateQuestion'

  export default {
    name: 'NewCustomerFormTemplate',
    components: {
      ModalFooterButton,
      CustomerFormTemplateQuestion
    },
    props: {
      customerFormId: String
    },
    data() {
      return {
        model: {
          id: "",
          formName: "",
          isSurvey: "0",
          customer_FormTemplateQuestion: [],
          customer_FormTemplateQuestionDeleted: []
        },
        yesNoList: [],
        questionTypes: []
      }
    },
    methods: {
      getYesNoList() {
        commonServices.getYesNoList().then((response) => {
          if (response) {
            this.yesNoList = response;
          }
        })
      },
      getQuestionTypes() {
        commonServices.getQuestionTypes().then((response) => {
          if (response) {
            this.questionTypes = response;
          }
        })
      },
      getCustomerFormTemplate: function () {
        if (this.model.id && this.model.id.length > 0) {
          customerFormTemplate.getCustomerFormTemplate(this.model.id)
            .then((result) => {
              if (result != null) {
                this.model = result;
                this.model.isSurvey = this.isSurveyNumeric(this.model.isSurvey);
              }
              else {
                this.$toastr.error(this.$t('Error'));
              }
            })
        }
      },
      isSurveyNumeric: function (val) {
        return val == false ? "0" : "1";
      },
      async submitNewCustomerFormTemplate() {
        const isValid = await this.$refs.customerFormTemplatebserver.validate();
        if (!isValid) {
          this.$toastr.error(this.$t('ValidationMessage.EmptyAndValidateError'));
          return;
        }

        if (this.model.customer_FormTemplateQuestion == null || this.model.customer_FormTemplateQuestion.length == 0) {
          this.$toastr.error(this.$t('ValidationMessage.EmptyAndValidateError'));
          return;
        }

        this.model.isSurvey = Number(this.model.isSurvey);

        customerFormTemplate.submitNewCustomerFormTemplate(this.model)
          .then((result) => {
            if (result) {
              this.$emit('closeModal');
              this.$toastr.success(this.$t('Success'));
              refreshSmartDuoData();
            }
            else {
              this.$toastr.error(this.$t('Error'));
            }
          })

      },
      addNewQuestion: function () {
        let newQuestion = {
          id: "",
          customerFormTemplateID: "",
          question: "",
          questionType: "text",
          questionOrder: this.model.customer_FormTemplateQuestion.length + 1,
          customer_FormTemplateQuestionOption: [],
          customer_FormTemplateQuestionOptionDeleted: [],
        };

        newQuestion.customerFormTemplateID = this.model.id;

        this.model.customer_FormTemplateQuestion.push(newQuestion);
      },
      CanMoveUpChildQuestion: function (_question) {
        let questionIndex = this.model.customer_FormTemplateQuestion.indexOf(_question);

        return questionIndex > 0;
      },
      CanMoveDownChildQuestion: function (_question) {
        let questionIndex = this.model.customer_FormTemplateQuestion.indexOf(_question);
        let lastIndex = this.model.customer_FormTemplateQuestion.length ?? 0;

        return questionIndex < lastIndex - 1;
      },
      reorderQuestions(question, direction) {
        if (direction == '+') {
          let upper = this.model.customer_FormTemplateQuestion.find(s => s.questionOrder == question.questionOrder - 1);
          if (upper != null)
            upper.questionOrder++;
          question.questionOrder--;
        }
        else {
          let bottom = this.model.customer_FormTemplateQuestion.find(s => s.questionOrder == question.questionOrder + 1);
          if (bottom != null)
            bottom.questionOrder--;
          question.questionOrder++;
        }

        this.model.customer_FormTemplateQuestion.sort((a, b) => parseFloat(a.questionOrder) - parseFloat(b.questionOrder));
      },
      CopyChildQuestion: function (copyQuestion) {
        this.model.customer_FormTemplateQuestion.unshift(copyQuestion);
      },
      DeleteChildQuestion: function (deleteQuestion) {
        if (deleteQuestion.id && deleteQuestion.id.length > 0) {
          this.model.customer_FormTemplateQuestionDeleted.push(deleteQuestion.id);
        }

        this.model.customer_FormTemplateQuestion = this.model.customer_FormTemplateQuestion.filter((s) => s != deleteQuestion);
      },
    },
    mounted() {
      this.getYesNoList();
      this.getQuestionTypes();

      if (this.customerFormId && this.customerFormId.length > 0) {
        this.model.id = this.customerFormId;
        this.getCustomerFormTemplate();
      }
      else {
        /* this.$store.dispatch("setModalTitle", this.$t("NewCustomerFormTemplate"));*/

        this.addNewQuestion();
      }
    }
  }
</script>
